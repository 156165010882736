/* @flow */

import React from 'react';
import PropTypes from 'prop-types';

function Checkbox(props: Object): Object {
  const {
    checked,
    onChange,
  } = props;
  return (
    <div className="checkbox">
      <button onClick={onChange}>
        {checked ? (
          <svg viewBox="0 0 4 4.2" enableBackground="new 0 0 4 4.2">
            <polygon points="1.8,4.2 0,2.5 0.6,1.9 1.6,2.9 3.3,0 4,0.4" />
          </svg>
        ) : null}
      </button>
    </div>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;

import 'react-app-polyfill/ie9';
import es6Promise from 'es6-promise';
import 'isomorphic-fetch';
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import cssVars from 'css-vars-ponyfill';

es6Promise.polyfill();

if (process.env.NODE_ENV === 'development') {
  window.DSHD = {
    theme_info: {
      color1: '#11aa11',
      color2: '#aa11aa',
    },
    system_name: '{system_name}',
    large_logo_url: 'https://liveswap.dshddev.com/assets/partner/logos/cs.svg',
    terms_url: '{terms_url}',
    privacy_url: '{privacy_url}',
  };
}

__webpack_nonce__ = window.jsNonce; // eslint-disable-line
if (process.env.NODE_ENV !== 'development') {
  const versionBuild = '201904101130';
  __webpack_public_path__ = `https://${window.assetsDomain}/prod/login/build/${versionBuild}/`; // eslint-disable-line
}

cssVars({
  variables: window.DSHD.theme_info,
});
ReactDOM.render(
  <App />,
  document.getElementById('root')
);

import React, { Component } from 'react';
import LayoutTextBoxInput from '@designhuddle/dshd-react-ui-kit/dist/LayoutTextBoxInput';
import classNames from 'classnames';
import Checkbox from './Checkbox';

let devUrl = '';
if (process.env.NODE_ENV === 'development') {
  devUrl = 'http://0.0.0.0:3001/https://liveswap.dshddev.com';
}

class PasswordManager extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      password: '',
      password2: '',
      savingPassword: false,
    };
    this.handlePasswordChange = this.handleFieldChange.bind(this, 'password');
    this.handlePassword2Change = this.handleFieldChange.bind(this, 'password2');
  }

  handleFieldChange(field: string, value: string) {
    this.setState({
      [field]: value,
    });
  }

  handleKeyPress = (value: string) => {
    if (value === 'Enter') {
      this.savePassword();
    }
  }

  hasValidPasswords() {
    return (
      this.state.password && this.state.password2 &&
      this.state.password === this.state.password2
    );
  }

  savePassword = () => {
    const { w, pr } = this.props.queryString;
    if (this.state.savingPassword || !this.hasValidPasswords()) return;
    this.setState({ savingPassword: true });
    const queryString = w ? '?agree=1' : '';
    const body = new URLSearchParams();
    body.append('token', w || pr);
    body.append('password', this.state.password);
    const headers = new Headers({});
    if (body instanceof URLSearchParams) {
      headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    }

    fetch(`${devUrl}/auth/resetpassword${queryString}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body,
    })
      .then(response => response.json()).then((data) => {
        if (data.success) {
          this.setState({ savingPassword: false });
          window.location.href = '/';
        } else {
          if (data.bad_token) {
            this.setState({ savingPassword: false });
            this.props.onError(data);
          } else {
            this.setState({ errorMsg: data.message, savingPassword: false });
          }
        }
      })
      .catch((error) => {
        this.setState({ savingPassword: false });
        this.props.onError(error);
      });
      return false;
  }

  onChangeTermsPolicyChbx = () => {
    this.setState({ termsPolicyChbx: !this.state.termsPolicyChbx });
  }

  render() {
    return (
      <div className="login password-manager">
        <div className="logo">
          <img
            src={`${window.DSHD.large_logo_url}`}
            alt="large_logo_url"
          />
        </div>
        <p>{this.props.queryString.pr ? 'Please enter a new password.' : 'Please enter a password.'}</p>
        {this.state.errorMsg && (
          <div className="error-msg">
            {this.state.errorMsg}
          </div>
        )}
        <form className="login-form" autoComplete="on">
          <LayoutTextBoxInput
            title="New Password:"
            placeholder=""
            value={this.state.password}
            onChange={this.handlePasswordChange}
            handleKeyUp={this.handlePasswordChange}
            handleKeyPress={this.handleKeyPress}
            type="password"
            maxLength={20}
          />
          <LayoutTextBoxInput
            title="Confirm Password:"
            placeholder=""
            value={this.state.password2}
            onChange={this.handlePassword2Change}
            handleKeyUp={this.handlePassword2Change}
            handleKeyPress={this.handleKeyPress}
            type="password"
            maxLength={20}
          />
        </form>
        {this.props.queryString.w ? (
          <div className="terms-policy-chbx">
            <div className="checkbox-tp">
              <Checkbox
                checked={this.state.termsPolicyChbx}
                onChange={this.onChangeTermsPolicyChbx}
              />
            </div>
            I agree to the&nbsp;
            <a href={window.DSHD.terms_url} target="_blank">
              Terms of Service
            </a>
            &nbsp;and&nbsp;
            <a href={window.DSHD.privacy_url} target="_blank">
              Privacy Policy
            </a>
          </div>
        ) : null}
        <button
          className={classNames('action-btn', {
            disabled: this.state.savingPassword || (
              this.props.queryString.w && !this.state.termsPolicyChbx
            ) || !this.hasValidPasswords(),
          })}
          onClick={this.savePassword}
        >
          {this.props.queryString.pr ? 'Reset Password' : 'Submit'}
        </button>
      </div>
    );
  }
}

export default PasswordManager;

/* @flow */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-modal';

export default class PopupModal extends Component<Object, Object> {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    contentLabel: PropTypes.string,
    popupClassName: PropTypes.string,
    title: PropTypes.any,
    titleP: PropTypes.string,
    onCancel: PropTypes.func,
    children: PropTypes.any,
    innerComponent: PropTypes.any,
  };

  constructor(props: Object) {
    super(props);
    this.state = { visibleClass: false };
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.clickOverlay = this.clickOverlay.bind(this);
  }

  state: Object;
  onCancel: Function;
  afterOpenModal: Function;
  clickOverlay: Function;

  onCancel() {
    this.setState({ visibleClass: false });
    setTimeout(() => {
      this.props.onCancel && this.props.onCancel();
    }, 100);
  }

  afterOpenModal() {
    this.setState({ visibleClass: true });
  }

  clickOverlay() {
    if (this.props.shouldCloseOnOverlayClick) {
      this.onCancel();
    }
  }

  render() {
    const {
      visible,
    } = this.props;
    return (
      <Modal
        isOpen={visible}
        onAfterOpen={this.afterOpenModal}
        style={{
          overlay: {
            position: 'absolute',
            zIndex: this.props.zIndex || 101,
          },
        }}
        onRequestClose={this.onCancel}
        contentLabel={this.props.contentLabel}
        className="react-modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={!!this.props.shouldCloseOnOverlayClick}
      >
        <div
          className={classNames('popup', this.props.popupClassName || '', {
            visible: this.state.visibleClass,
          })}
        >
          <div className="popup-overlay" onClick={this.clickOverlay} />
          <div className="popup-inner">
            {this.props.noClose ? null : (
              <div className="popup-actions">
                <a
                  className="popup-close"
                  onClick={this.onCancel}
                >
                  {window.isMobile ? (
                    <svg x="0px" y="0px" viewBox="0 0 18 18">
                      <path d="M10.41,9l7.29-7.29c0.39-0.39,0.39-1.02,0-1.41s-1.02-0.39-1.41,0L9,7.59L1.71,0.29 c-0.39-0.39-1.02-0.39-1.41,0s-0.39,1.02,0,1.41L7.59,9l-7.29,7.29c-0.39,0.39-0.39,1.02,0,1.41C0.49,17.9,0.74,18,1,18 s0.51-0.1,0.71-0.29L9,10.41l7.29,7.29C16.49,17.9,16.74,18,17,18s0.51-0.1,0.71-0.29c0.39-0.39,0.39-1.02,0-1.41L10.41,9z"></path>
                    </svg>
                  ) : (
                    <i className="ico-x" />
                  )}
                </a>
              </div>
            )}
            {this.props.innerComponent ? this.props.innerComponent(this.onCancel) : (
              <Fragment>
                <header className="popup-head">
                  <h1 className="popup-title">{this.props.title}</h1>
                  <p>{this.props.titleP}</p>
                </header>
                <div className="popup-body">
                  {this.props.children}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

import React, { Fragment } from 'react';

import { PopupContext } from '../contexts/popup-context';

import PopupForgotPassword from '../components/PopupForgotPassword';
import PopupPasswordSent from '../components/PopupPasswordSent';
import PopupError from '../components/PopupError';

function Modals() {
  return (
    <Fragment>
      <PopupContext.Consumer>
        {({ popupForgotPassword, popupPasswordSent, popupError }) => (
          <Fragment>
            <PopupForgotPassword {...popupForgotPassword} />
            <PopupPasswordSent {...popupPasswordSent} />
            <PopupError {...popupError} />
          </Fragment>
        )}
      </PopupContext.Consumer>
    </Fragment>
  );
}

export default Modals;
